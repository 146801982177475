import leftmenu_logo from "./common/leftmenu/leftmenu_logo.svg";
import leftmenu_logo_full from "./common/leftmenu/leftmenu_logo_full.svg";
import leftmenu_btn from "./common/leftmenu/leftmenu_btn.svg";
import leftmenu_btn_mo from "./common/leftmenu/leftmenu_btn_mo.svg";
import leftmenu_lslogo from "./common/leftmenu/leftmenu_LS.svg";
import leftmenu_lslogo_full from "./common/leftmenu/leftmenu_LS_full.svg";
import leftmenu_tooltip from "./common/leftmenu/leftmenu_tooltip.svg";
import leftmenu_icon01 from "./common/leftmenu/leftmenu_icon01.svg";
import leftmenu_icon02 from "./common/leftmenu/leftmenu_icon02.svg";
import leftmenu_icon03 from "./common/leftmenu/leftmenu_icon03.svg";
import leftmenu_icon04 from "./common/leftmenu/leftmenu_icon04.svg";
import leftmenu_icon05 from "./common/leftmenu/leftmenu_icon05.svg";
import leftmenu_bticon01 from "./common/leftmenu/leftmenu_bt_icon01.svg";
import leftmenu_bticon02 from "./common/leftmenu/leftmenu_bt_icon02.svg";
import leftmenu_bticon03 from "./common/leftmenu/leftmenu_bt_icon03.svg";

import leftmenu_icon01_on from "./common/leftmenu/leftmenu_icon01_on.svg";
import leftmenu_icon02_on from "./common/leftmenu/leftmenu_icon02_on.svg";
import leftmenu_icon03_on from "./common/leftmenu/leftmenu_icon03_on.svg";
import leftmenu_icon04_on from "./common/leftmenu/leftmenu_icon04_on.svg";
import leftmenu_icon05_on from "./common/leftmenu/leftmenu_icon05_on.svg";
import leftmenu_bticon01_on from "./common/leftmenu/leftmenu_bt_icon01_on.svg";
import leftmenu_bticon02_on from "./common/leftmenu/leftmenu_bt_icon02_on.svg";
import leftmenu_bticon03_on from "./common/leftmenu/leftmenu_bt_icon03_on.svg";

import appbar_setting from "./common/appbar_setting.svg";
import appbar_account from "./common/appbar_account.svg";
import appbar_help from "./common/appbar_help.svg";
import appbar_dashboard from "./common/appbar_dashboard.svg";

import icon_close_white from "./icon/icon_close_white.svg";
import icon_close from "./icon/icon_close.svg";
import icon_refresh from "./icon/icon_refresh.svg";
import icon_home from "./icon/icon_home.svg";
import icon_noti from "./icon/icon_noti.svg";
import icon_arrowr from "./icon/icon_arrowr.svg";
import icon_box_arrowd from "./icon/icon_box_arrowd.svg";
import icon_box_arrowd_on from "./icon/icon_box_arrowd_on.svg";
import icon_arrowd from "./icon/icon_arrowd.svg";
import icon_folder from "./icon/icon_folder.svg";
import icon_search from "./icon/icon_search.svg";
import icon_search_prev from "./icon/icon_search_prev.svg";
import icon_circle_arrowr from "./icon/icon_circle_arrowr.svg";
import icon_more from "./icon/icon_more.svg";
import icon_add_circle from "./icon/icon_add_circle.svg";
import icon_delete from "./icon/icon_delete.svg";
import icon_filter_off from "./icon/icon_filter_off.svg";
import icon_filter_arrowd from "./icon/icon_filter_arrowd.svg";
import icon_filter_sort from "./icon/icon_filter_sort.svg";
import page_first from "./common/page_first.svg";
import page_pre from "./common/page_pre.svg";
import icon_arrowd_bk from "./icon/icon_arrowd_bk.svg";
import icon_location from "./icon/icon_location.svg";
import icon_download from "./icon/icon_download.svg";
import icon_info from "./icon/icon_info.svg";
import icon_info_blue from "./icon/icon_info_blue.svg";
import icon_account from "./icon/icon_account.svg";
import icon_setting from "./icon/icon_setting.svg";
import icon_logout from "./icon/icon_logout.svg";
import icon_document_xls from "./icon/icon_document_xls.svg";
import icon_document_pdf from "./icon/icon_document_pdf.svg";
import icon_date_arrow from "./icon/icon_date_arrow.svg";
import icon_add_user from "./icon/icon_add_user.svg";
import icon_add from "./icon/icon_add.svg";
import icon_add_white from "./icon/icon_add_white.svg";
import icon_close_blue from "./icon/icon_close_blue.svg";
import icon_save_check from "./icon/icon_save_check.svg";
import icon_sort_dnd from "./icon/icon_sort_dnd.svg";
import icon_handle_dnd from "./icon/icon_handle_dnd.svg";
import icon_save_file from "./icon/icon_save_file.svg";
import icon_help from "./icon/icon_help.svg";
import icon_back from "./icon/icon_back.svg";

import icon_auth_sms from "./page/icon_auth_sms.svg";
import icon_auth_refresh from "./page/icon_auth_refresh.svg";
import icon_auth_succ from "./page/icon_auth_succ.svg";
import icon_auth_requ from "./page/icon_auth_requ.svg";
import img_error_wait from "./page/img_error_wait.svg";
import img_error_search from "./page/img_error_search.svg";

import input_date from "./common/input_date.svg";
import input_chk from "./common/input_chk.svg";
import input_select from "./common/input_select.svg";
import input_error from "./common/input_error.svg";

import we_info01 from "./common/weather/we_info01.svg";
import we_info02 from "./common/weather/we_info02.svg";
import d01 from "./common/weather/01d.png";
import n01 from "./common/weather/01n.png";
import d02 from "./common/weather/02d.png";
import n02 from "./common/weather/02n.png";
import dn03 from "./common/weather/03dn.png";
import dn04 from "./common/weather/04dn.png";
import dn09 from "./common/weather/09dn.png";
import d10 from "./common/weather/10d.png";
import n10 from "./common/weather/10n.png";
import dn11 from "./common/weather/11dn.png";
import dn13 from "./common/weather/13dn.png";
import dn50 from "./common/weather/50dn.png";
import icon_project_plus from "./icon/icon_project_plus.svg";
import icon_project_plus_white from "./icon/icon_project_plus_white.svg";
import add_img from "./common/add_img.svg";
import icon_direction_back from "./icon/icon_direction_back.svg";
import icon_direction_for from "./icon/icon_direction_for.svg";
import icon_speed_acc from "./icon/icon_speed_acc.svg";
import icon_speed_dec from "./icon/icon_speed_dec.svg";
import icon_speed_con from "./icon/icon_speed_con.svg";
import icon_speed_dwell from "./icon/icon_speed_dwell.svg";
import icon_speed_stop from "./icon/icon_speed_stop.svg";
import icon_speed_search from "./icon/icon_speed_search.svg";
import icon_speed_warning from "./icon/icon_speed_warning.svg";
import icon_energy from "./icon/icon_energy.svg";
import icon_co2 from "./icon/icon_co2.svg";
import icon_date from "./icon/icon_date.svg";
import img_bearing from "./page/img_bearing.png";
import img_motor from "./page/img_motor.png";
import img_coil from "./page/img_coil.png";
import img_power from "./page/img_power.png";
import icon_projectlist from "./page/icon_projectlist.svg";
import img_empty from "./page/img_empty.svg";
import help_header from "./page/help_header.png";
import empty_asset from "./page/empty_asset.svg";
import icon_demopjt_add from "./page/icon_demopjt_add.svg";
import icon_demopjt_delete from "./page/icon_demopjt_delete.svg";
import error_404 from "./page/error_404.svg";
import empty_dashboard from "./page/dashboard/empty_dashboard.svg";
import dashboard_tab_play from "./page/dashboard/dashboard_tab_play.svg";
import dashboard_tab_stop from "./page/dashboard/dashboard_tab_stop.svg";
import icon_zoom from "./icon/icon_zoom.svg";
import widgets_close from "./page/dashboard/widgets_close.svg";
import widgets_handle from "./page/dashboard/widgets_handle.svg";
import widgets_edit from "./page/dashboard/widgets_edit.svg";
import list_icon_asset from "./page/dashboard/list_icon_asset.svg";
import list_icon_list from "./page/dashboard/list_icon_list.svg";
import list_icon_linechart from "./page/dashboard/list_icon_linechart.svg";
import list_icon_barchart from "./page/dashboard/list_icon_barchart.svg";
import list_icon_gaugechart from "./page/dashboard/list_icon_gaugechart.svg";
import list_icon_table from "./page/dashboard/list_icon_table.svg";
import list_icon_map from "./page/dashboard/list_icon_map.svg";

import icon_history from "./icon/icon_history.svg";
import icon_history_white from "./icon/icon_history_white.svg";

export const AssetImages = {
  common: {
    appbar: {
      setting: () => {
        return appbar_setting;
      },
      account: () => {
        return appbar_account;
      },
      help: () => {
        return appbar_help;
      },
      dashboard: () => {
        return appbar_dashboard;
      }
    },
    leftmenu: {
      logo: () => {
        return leftmenu_logo;
      },
      logoFull: () => {
        return leftmenu_logo_full;
      },
      LSlogo: () => {
        return leftmenu_lslogo;
      },
      LSlogoFull: () => {
        return leftmenu_lslogo_full;
      },
      openBtn: () => {
        return leftmenu_btn;
      },
      openBtnMo: () => {
        return leftmenu_btn_mo;
      },
      icon: (image: string) => {
        if (image == "icon01.svg") return leftmenu_icon01;
        else if (image == "icon02.svg") return leftmenu_icon02;
        else if (image == "icon03.svg") return leftmenu_icon03;
        else if (image == "icon04.svg") return leftmenu_icon04;
        else if (image == "icon05.svg") return leftmenu_icon05;
        else if (image == "bt_icon01.svg") return leftmenu_bticon01;
        else if (image == "bt_icon02.svg") return leftmenu_bticon02;
        else if (image == "bt_icon03.svg") return leftmenu_bticon03;
      },
      iconOn: (image: string) => {
        if (image == "icon01.svg") return leftmenu_icon01_on;
        else if (image == "icon02.svg") return leftmenu_icon02_on;
        else if (image == "icon03.svg") return leftmenu_icon03_on;
        else if (image == "icon04.svg") return leftmenu_icon04_on;
        else if (image == "icon05.svg") return leftmenu_icon05_on;
        else if (image == "bt_icon01.svg") return leftmenu_bticon01_on;
        else if (image == "bt_icon02.svg") return leftmenu_bticon02_on;
        else if (image == "bt_icon03.svg") return leftmenu_bticon03_on;
      },
      toolTipArrow: () => {
        return leftmenu_tooltip;
      },
    },
    input: {
      inputDate: () => {
        return input_date;
      },
      inputCheck: () => {
        return input_chk;
      },
      inputSelect: () => {
        return input_select;
      },
      inputError: () => {
        return input_error;
      },
    },
    icon: {
      closeWhite: () => {
        return icon_close_white;
      },
      close: () => {
        return icon_close;
      },
      closeBlue: () => {
        return icon_close_blue;
      },
      refresh: () => {
        return icon_refresh;
      },
      home: () => {
        return icon_home;
      },
      noti: () => {
        return icon_noti;
      },
      folder: () => {
        return icon_folder;
      },
      arrowRight: () => {
        return icon_arrowr;
      },
      boxArrowDown: () => {
        return icon_box_arrowd;
      },
      boxArrowDownOn: () => {
        return icon_box_arrowd_on;
      },
      arrowDown: () => {
        return icon_arrowd;
      },
      search: () => {
        return icon_search;
      },
      searchPrev: () => {
        return icon_search_prev;
      },
      circleArrowRt: () => {
        return icon_circle_arrowr;
      },
      more: () => {
        return icon_more;
      },
      circleAdd: () => {
        return icon_add_circle;
      },
      delete: () => {
        return icon_delete;
      },
      filterOff: () => {
        return icon_filter_off;
      },
      filterArrow: () => {
        return icon_filter_arrowd;
      },
      filterSort: () => {
        return icon_filter_sort;
      },
      pageFirst: () => {
        return page_first;
      },
      pagePrev: () => {
        return page_pre;
      },
      arrowDownBk: () => {
        return icon_arrowd_bk;
      },
      location: () => {
        return icon_location;
      },
      download: () => {
        return icon_download;
      },
      toolTip: () => {
        return icon_info;
      },
      toolTipBu: () => {
        return icon_info_blue;
      },
      setting: () => {
        return icon_setting;
      },
      account: () => {
        return icon_account;
      },
      logout: () => {
        return icon_logout;
      },
      documentXls: () => {
        return icon_document_xls;
      },
      documentPdf: () => {
        return icon_document_pdf;
      },
      dateArrow: () => {
        return icon_date_arrow;
      },
      nodata: () => {
        return img_empty;
      },
      date: () => {
        return icon_date;
      },
      addUser: () => {
        return icon_add_user;
      },
      add: () => {
        return icon_add;
      },
      addWhite: () => {
        return icon_add_white;
      },
      saveCheck: () => {
        return icon_save_check;
      },
      sortDnD: () => {
        return icon_sort_dnd;
      },
      handleDnD: () => {
        return icon_handle_dnd;
      },
      saveFile: () => {
        return icon_save_file;
      },
      help: () => {
        return icon_help;
      },
      back: () => {
        return icon_back;
      },
      zoom: () => {
        return icon_zoom;
      },
      history: () => {
        return icon_history;
      },
      historyWhite: () => {
        return icon_history_white;
      }
    },
    weather: {
      info01: () => {
        return we_info01;
      },
      info02: () => {
        return we_info02;
      },
      icon: {
        d01: () => {
          return d01;
        },
        n01: () => {
          return n01;
        },
        d02: () => {
          return d02;
        },
        n02: () => {
          return n02;
        },
        dn03: () => {
          return dn03;
        },
        dn04: () => {
          return dn04;
        },
        dn09: () => {
          return dn09;
        },
        d10: () => {
          return d10;
        },
        n10: () => {
          return n10;
        },
        dn11: () => {
          return dn11;
        },
        dn13: () => {
          return dn13;
        },
        dn50: () => {
          return dn50;
        },
      },
    },
  },
  monitor: {
    projectPlus: () => {
      return icon_project_plus;
    },
    projectPlusW: () => {
      return icon_project_plus_white;
    },
    addImage: () => {
      return add_img;
    },
    backward: () => {
      return icon_direction_back;
    },
    forward: () => {
      return icon_direction_for;
    },
    acceleration: () => {
      return icon_speed_acc;
    },
    deceleration: () => {
      return icon_speed_dec;
    },
    constant: () => {
      return icon_speed_con;
    },
    dwell: () => {
      return icon_speed_dwell;
    },
    search: () => {
      return icon_speed_search;
    },
    warning: () => {
      return icon_speed_warning;
    },
    stop: () => {
      return icon_speed_stop;
    },
    energy: () => {
      return icon_energy;
    },
    co2: () => {
      return icon_co2;
    },
    bearing: () => {
      return img_bearing;
    },
    motor: () => {
      return img_motor;
    },
    coil: () => {
      return img_coil;
    },
    power: () => {
      return img_power;
    },
    projectList: () => {
      return icon_projectlist;
    },
  },
  etc: {
    authSMS: () => {
      return icon_auth_sms;
    },
    authRefresh: () => {
      return icon_auth_refresh;
    },
    authSuccess: () => {
      return icon_auth_succ;
    },
    authRequest: () => {
      return icon_auth_requ;
    },
    errorWait: () => {
      return img_error_wait;
    },
    errorSearch: () => {
      return img_error_search;
    },
    helpHeader: () => {
      return help_header;
    },
    emptyAsset: () => {
      return empty_asset;
    },
    demoPjtAdd: () => {
      return icon_demopjt_add;
    },
    demoPjtDelete: () => {
      return icon_demopjt_delete;
    },
    Error404: () => {
      return error_404;
    }
  },
  dashboard: {
    emptyCard: () => {
      return empty_dashboard;
    },
    tabPlay: () => {
      return dashboard_tab_play;
    },
    tabStop: () => {
      return dashboard_tab_stop;
    },
    widgetsClose: () => {
      return widgets_close;
    },
    widgetsEdit: () => {
      return widgets_edit;
    },
    widgetsHandle: () => {
      return widgets_handle;
    },
    listIconAsset: () => {
      return list_icon_asset;
    },
    listIconList: () => {
      return list_icon_list;
    },
    listIconLineChart: () => {
      return list_icon_linechart;
    },
    listIconBarChart: () => {
      return list_icon_barchart;
    },
    listIconGaugeChart: () => {
      return list_icon_gaugechart;
    },
    listIconTable: () => {
      return list_icon_table;
    },
    listIconMap: () => {
      return list_icon_map;
    },
  }
};