import {resi18nutil} from 'language/resi18nutil';

// I18N_AST_ 를 asset language prefix 로 사용한다.
const resi18n_asset = {

  I18N_AST_ERROR_404: ['페이지를 찾을 수 없습니다.', 'Page not found', undefined],

  I18N_AST_ALARM_CONFIRM: ['선택 알람 확인', 'Confirm', undefined],
  I18N_AST_ALL_CONFIRM: ['전체 알람 확인', 'All Confirm', undefined],
  I18N_AST_CONFIRM: ['확인', 'Confirm', undefined],
  I18N_AST_NODATA: ['데이터가 없습니다.', 'No Data Available', undefined],

  I18N_AST_MONITOR_REQUIRED_VALUE: ['필수 값 입니다.', 'Required', undefined],

  I18N_AST_UPDATE_COMPLETE: ['성공적으로 저장했습니다.', 'It has been successfully saved.', undefined],
  I18N_AST_UPDATE_FAIL: ['정상적으로 저장되지 않았습니다.', 'It wasn\'t saved properly.', undefined],
  I18N_AST_DISABLE: ['사용자에게 더 이상 기기가 보이지 않게 됩니다.', 'The asset is no longer visible to the user.', undefined],
  I18N_AST_SAVE_COMPLETE: ['성공적으로 저장했습니다.', 'It has been successfully saved.', undefined],
  I18N_AST_FAIL: ['정상적으로 저장되지 않았습니다.', 'It wasn\'t saved properly.', undefined],

  // 파라미터 페이지
  I18N_AST_PARAMETER_DELETE_TITLE: ['파라미터 파일 삭제', 'Parameter File Delete', undefined],
  I18N_AST_PARAMETER_DELETE_MSG: ['선택한 파일을 정말 삭제 하시겠습니까?', 'Are you sure you want to delete the selected files?', undefined],
  I18N_AST_PARAMETER_SAVE_TITLE: ['파라미터 저장', 'Parameter Save', undefined],
  I18N_AST_PARAMETER_SAVE_MSG: ['불러온 파라미터를 파일로 저장하시겠습니까? 파일은 파라미터 파일 탭에 저장됩니다.', 'Do you want to save the parameters to a file? The file will be saved on the Parameter File tab.', undefined],
  I18N_AST_PARAMETER_DOWN_TITLE: ['파라미터 다운로드', 'Parameter Download', undefined],
  I18N_AST_PARAMETER_DOWN_MSG: ['파라미터를 다운로드 하시겠습니까?', 'Do you want to download the parameters to a file?', undefined],
  I18N_AST_PARAMETER_FILE: ['파라미터 파일', 'Parameter File', undefined],
  I18N_AST_PARAMETER_FILE_MO: ['파일', 'File', undefined],
  I18N_AST_PARAMETER_GROUP: ['구분', 'Group', undefined],
  I18N_AST_PARAMETER_VALUE: ['값', 'Value', undefined],
  I18N_AST_PARAMETER_UNIT: ['단위', 'Unit', undefined],
  I18N_AST_PARAMETER_RANGE: ['범위', 'Range', undefined],
  I18N_AST_PARAMETER_DEFAULT: ['초깃값', 'Default', undefined],
  I18N_AST_PARAMETER_SCALE: ['스케일', 'Scale', undefined],
  I18N_AST_PARAMETER_FILE_NAME: ['파일명', 'File Name', undefined],
  I18N_AST_PARAMETER_FILE_TYPE: ['구분', 'File Type', undefined],
  I18N_AST_PARAMETER_ASSET: ['기기', 'Asset', undefined],
  I18N_AST_PARAMETER_DETAILS: ['상세', 'Details', undefined],
  I18N_AST_PARAMETER_ERROR_MSG: ['오류가 발생했습니다. 다시 시도해 주세요.', 'Error Occurred, Try Again.', undefined],
  I18N_AST_PARAMETER_WAIT_MSG: ['데이터를 불러오는 데 수 분이 걸릴 수 있습니다.<br/>데이터를 보시려면 버튼을 눌러주세요.', 'This will take a few minutes.<br/>Do you want to read the parameters?', undefined],
  I18N_AST_PARAMETER_READ_BTN: ['Parameter 보기', 'Read', undefined],
  I18N_AST_PARAMETER_CHANGED_VALUES: ['변경 값만 보기', 'Changed Values Only', undefined],
  I18N_AST_PARAMETER_CHANGED_NOTSAVED_MSG: ['읽어온 파라미터 데이터가 사라집니다. 저장 아이콘을 통해 보관이 가능합니다.', 'The read parameter data disappears. It can be archived via the save icon', undefined],

  // 툴팁 메세지 관련
  I18N_AST_TOOLTIP_CONNECTION: ['연결이 해제된 후 설정된 시간이 지나면\n알람이 발생합니다. ', 'An alarm occurs after a set amount of time has passed since the disconnection. ', undefined],
  I18N_AST_TOOLTIP_UN_AUTH_ASSET: ['미인증 자산', "Unauthenticated Asset", undefined],

  // QR 기기등록 씬
  // 미등록 기기 팝업
  I18N_AST_AUTH_NOT_REGISTER_TITLE: ['미등록 기기', 'Unregistered Asset', undefined],
  I18N_AST_AUTH_NOT_REGISTER_CONTENT_TOP: ['이 기기의 소유자 정보가 없습니다. 소유자로 등록하기 위해서는 Solution Square 계정의 전화번호와 Drive의 소유자 휴대폰번호가 일치해야 합니다.', 'The phone number in your Solution Square account must match the owner\'s phone number in Drive in order to be registration as the owner.', undefined],
  I18N_AST_AUTH_NOT_REGISTER_CONTENT_BOTTOM: ['<a href="" style="color:#407EDB;font-family: \'Pretendard Medium",sans-serif\'">Drive Dashboard</a>에서 유지보수 > 소유자정보 > 휴대폰번호에 인증용 휴대폰번호를 입력해주세요.', 'In <a href="" style="color:#407EDB;font-family: \'Pretendard Medium",sans-serif\'">Drive Web Dashboard</a>, go to Maintenance > Owner Information > Mobile Phone and enter your mobile phone number for verification.', undefined],
  I18N_AST_AUTH_NOT_REGISTER_CONTENT_CONNECT_INTERNET: ['계정 정보 세팅 후 드라이브를 인터넷에 연결해 주세요.', 'After setting up your account information, connect your drive to the internet.', undefined],

  // 소유주에게 권한 요청 팝업
  I18N_AST_AUTH_REQUEST_TO_OWNER_TITLE: ['사용 권한 요청', 'Permission Requests', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_TOP: ['해당 자산은 아래 휴대폰번호의 소유자에게 이미 등록되어 있는 자산 입니다. 소유자에게 사용 권한을 요청하시겠습니까?', 'The asset is already registered to the owner of the phone number below. Do you want to request permission from the owner?', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_BOTTOM_LEFT: ['기기 소유자이나 휴대폰번호가 변경된 경우라면,', 'If you\'re the asset owner and your phone number has changed,', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_BOTTOM_RIGHT: ['<a href="" style="color:#407EDB;font-family: \'Pretendard Medium",sans-serif\'">Drive Dashboard</a>에서 변경된 휴대폰번호를 입력해주세요.', 'Enter the changed phone number in your <a href="" style="color:#407EDB;font-family: \'Pretendard Medium",sans-serif\'">Drive Web Dashboard</a>', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER_CONTENT_CONNECT_INTERNET: ['계정 정보 세팅 후 드라이브를 인터넷에 연결해 주세요.', 'After setting up your account information, connect your drive to the internet.', undefined],
  I18N_AST_AUTH_REQUEST_TO_OWNER: ['인증 요청하기', 'Request Authentication', undefined],

  I18N_AST_OWNER_ERR_TITLE: ["소유자 인증 오류", "Owner Authentication Error", undefined],
  I18N_AST_OWNER_ERR_MSG1: ['소유자 인증에 실패하였습니다.', 'Owner authentication failed.', undefined],
  I18N_AST_OWNER_ERR_MSG2: ['소유자 인증 문제를 해결한 후 기기를 공유받을 수 있습니다.', 'After you resolve the owner authentication issue, you can share the asset.', undefined],

  // 사용자 인증 팝업
  I18N_AST_USER_AUTH: ['사용자 인증', 'User Authentication', undefined],
  I18N_AST_USER_AUTH_CONTENT: ['휴대폰번호를 확인한 후 \'인증하기\' 버튼을 클릭해 주세요. 인증 SMS가 발송됩니다. 휴대폰번호가 맞지 않으면 Solution Square 계정의 휴대폰번호를 변경해 주세요.', 'After verifying your phone number, click the \'Verify\' button and you\'ll receive a verification SMS. If your phone number is incorrect, change the phone number on your Solution Square account.', undefined],
  I18N_AST_AUTH: ['인증하기', 'Verify', undefined],
  I18N_AST_AUTH_CHANGE_ACCOUNT: ['계정 설정 변경하기', 'Change your account settings', undefined],

  // 인증 완료 팝업
  I18N_AST_AUTH_SUCCESS: ['인증 완료', 'Authentication Complete', undefined],
  I18N_AST_AUTH_SUCCESS_MSG: ['사용자 인증이 완료되었습니다.', 'User authentication is complete.', undefined],

  // 사용자, 관리자 문자 보내는 팝업
  I18N_AST_SMS_TO_OWNER: ['권한 인증', 'Authorization', undefined],
  I18N_AST_SMS_TO_OWNER_MSG: ['소유자에게 인증 SMS가 발송되었습니다. 소유자에게 발송된 SMS의 인증 번호를 전달 받아 입력한 후 인증을 확인해 주세요.', 'A verification SMS has been sent to the owner. Receive the verification number from the owner, enter it, and confirm the verification.', undefined],
  I18N_AST_SMS_TO_USER: ['사용자 인증', 'User Authentication', undefined],
  I18N_AST_SMS_TO_USER_MSG: ['인증 SMS가 발송되었습니다. 발송된 문자의 인증 번호를 입력한 후 인증 확인 버튼을 눌러주세요.', 'A verification SMS has been sent to you. Please enter the verification number from the SMS and confirm the verification.', undefined],
  I18N_AST_REQUEST_SMS: ['인증 문자 재발송', 'Resend verification text', undefined],
  I18N_AST_AUTH_OK: ['인증 확인', 'Verify', undefined],

  // alert 창 문구 모음
  I18N_AST_AST_IS_ACTIVATED: ['자산이 활성화 되었습니다.', 'The asset has been enabled.', undefined],
  // 문자 인증 시, 핸드폰 번호가 유효하지 않을 경우
  I18N_AST_PHONE_NUM_NOT_VALIDATE: ['유효하지 않은 휴대폰 번호입니다.', 'Invalid mobile number.', undefined],
  // 인증 번호 요층에 실패 하였을 때
  I18N_AST_PHONE_FAIL_AUTH: ['인증번호 요청에 실패했습니다. 잠시 후 다시 시도해주세요.', 'The request failed, please try again later.', undefined],
  // 문자 인증 시간 초과 시 나오는 alert
  I18N_AST_SMS_TIME_OUT: ['인증 시간이 만료되었습니다. 인증 문자를 재발송해주세요.', 'The verification time has expired, please resend the verification text.', undefined],
  // 안내 alert
  I18N_AST_AUTH_SAVE_SUCCESS: ['권한을 등록했습니다.', 'Authorization has been registered.', undefined],
  I18N_AST_AUTH_INCORRECT: ['코드가 일치하지 않습니다.', 'The code doesn\'t match.', undefined],
  // 프로젝트 <-> 디바이스 매핑 삭제 성공 / 실패 메시지
  I18N_AST_SUCCESS_DELETE: ['성공적으로 삭제되었습니다.', 'It has been successfully deleted.', undefined],
  I18N_AST_FAIL_DELETE: ['정상적으로 삭제되지 않았습니다.', 'It wasn\'t deleted properly.', undefined],
  // 문자 인증 프로세스에서, 권한이 이미 있거나, 기기가 이미 등록되어 있는 경우의 안내
  I18N_AST_ALREADY_SHARED: ['이미 공유된 기기입니다.', 'The asset is already being shared.', undefined],
  I18N_AST_ALREADY_REGISTER_DEVICE: ['이미 등록 된 기기입니다.', 'The asset is already being registered.', undefined],
  // 문자 인증 도중에 팝업을 닫았을 때 나오는 예외 메시지
  I18N_AST_RE_AUTH_SUCCESS: ['자산을 재인증했습니다.', 'The asset has been reauthenticate.', undefined],
  I18N_AST_GUIDE_RE_AUTH: ['재인증 후 사용할 수 있습니다.', 'The asset will be available after reauthenticate.', undefined],
  // Settings 페이지에서 기기를 Disabled 시킬 때, 나오는 안내 메시지
  I18N_AST_SETTING_DISABLE_ASSET_TITLE: ['Asset 미사용', 'Disable Asset', undefined],
  I18N_AST_SETTING_DISABLE_ASSET_MSG: ['시스템에 더이상 기기가 보이지 않게 됩니다. 계속 하시겠습니까?', 'The system no longer sees the asset. Do you want to continue?', undefined],
  // Settings 페이지에서 기기를 삭제 시킬 때, 나오는 안내 메시지
  I18N_AST_SETTING_DELETE_ASSET_TITLE: ['Asset 삭제', 'Delete Asset', undefined],
  I18N_AST_SETTING_DELETE_ASSET_MSG: ['선택하신 {{ea}}개의 Asset을 삭제하시겠습니까?', 'Are you sure you want to delete the {{ea}} selected assets?', undefined],
  // Trend 에서 다운로드 눌렀을 때, 선택된 기기나 프로젝트가 없는 경우 에러메시지 처리
  I18N_AST_TREND_DOWNLOAD_NOT_SELECTED_PJT_OR_DEVS: ['선택된 프로젝트 또는 자산이 없습니다.', 'No projects or assets are selected.', undefined],
  // Trend 에서 다운로드를 눌렀을 때, 선택된 기기나 프로젝트가 있으나, 해당 날짜에 파일이 존재하지 않는 경우
  I18N_AST_TREND_DOWNLOAD_NOT_EXIST_DEVICE_CSV_FILE: ['해당 {{each}} 기기의 {{date}}일자 파일이 존재하지 않습니다.', 'There are no files dated {{date}} on that {{each}} asset.', undefined],
  I18N_AST_TREND_DOWNLOAD_NOT_EXIST_FILE: ['{{date}}일자 데이터가 없습니다.', '{{date}} Date data does not exist.', undefined],
  // 프로젝트, 디바이스 삭제 성공 / 삭제 실패 예외 메시지
  I18N_AST_PJT_DELETE_SUCCESS: ['프로젝트가 성공적으로 삭제되었습니다.', 'The project has been successfully deleted.', undefined],
  I18N_AST_PJT_DELETE_FAIL: ['프로젝트가 정상적으로 삭제되지 않았습니다.', 'The project was not deleted properly.', undefined],
  I18N_AST_DEV_DELETE_SUCCESS: ['자산이 성공적으로 삭제되었습니다.', 'The asset has been successfully deleted.', undefined],
  I18N_AST_DEV_DELETE_FAIL: ['자산이 정상적으로 삭제되지 않았습니다.', 'The asset was not deleted properly.', undefined],

  // DatePicker에서 종료일이 입력 안되었을 때, 예외처리 문구
  I18N_AST_DATE_NOT_SELECT_END_DATE: ['종료일을 입력해 주세요.', 'Please enter an end date.', undefined],
  I18N_AST_DATE_NOT_END_DATE: ['종료 날짜 선택', 'End date', undefined],
  // Default Projects 에서의 ProjectDescription
  I18N_AST_DEFAULT_PROJECT_DESC: ['기본으로 생성되는 프로젝트 입니다.', 'This project is created by default.', undefined],


  I18N_AST_ALL: ['전체', 'All', undefined],
  I18N_AST_ASSET: ['에셋', 'Asset', undefined],
  I18N_AST_PROJECT: ['프로젝트', 'Project', undefined],
  I18N_AST_DEVICE: ['기기 명', 'Asset', undefined],
  I18N_AST_DASHBOARD: ['대시보드', 'Dash Board', undefined],
  I18N_AST_MONITORING: ['모니터링', 'Monitoring', undefined],
  I18N_AST_TRENDS: ['트렌드', 'Trends', undefined],
  I18N_AST_TREND: ['트렌드', 'Trend', undefined],
  I18N_AST_ALARM: ['알람', 'Alarm', undefined],
  I18N_AST_PARAMETER: ['파라미터', 'Parameter', undefined],
  I18N_AST_SETTING: ['설정', 'Setting', undefined],
  I18N_AST_SETTINGS: ['설정', 'Settings', undefined],
  I18N_AST_HELP_GUIDE: ['도움말', 'Help Guide', undefined],
  I18N_AST_USER_ACCOUNT: ['계정 설정', 'User Account', undefined],


  I18N_AST_MONITOR_NOT_AUTH_DEVICE: ['소유권이 인증되지 않은 기기가 있습니다.', 'There are unauthenticated assets.', undefined],
  I18N_AST_MONITOR_NOT_AUTH_DEVICE_LINK: ['바로가기', 'Go to Setting', undefined],
  I18N_AST_MONITOR_TOTAL_ASSETS: ['전체 기기', 'Total Assets', undefined],
  I18N_AST_MONITOR_TOTAL_ENERGY: ['금일 사용량', 'Total Energy (24h)', undefined],
  I18N_AST_MONITOR_PROJECT_DETAILS: ['프로젝트 상세', 'Project Details', undefined],
  I18N_AST_MONITOR_THUMB_NAIL_IMAGE: ['미리보기 이미지', 'Thumb Nail Image', undefined],
  I18N_AST_MONITOR_PROJECT_NAME: ['프로젝트 명', 'Project Name', undefined],
  I18N_AST_MONITOR_DESCRIPTION: ['설명', 'Description', undefined],
  I18N_AST_MAX_TWENTY_CHARACTER: ['20자 이하 텍스트', 'Max. 20 Characters', undefined],
  I18N_AST_MONITOR_MAX_FIFTY_CHARACTER: ['50자 이하 텍스트', 'Max. 50 Characters', undefined],
  I18N_AST_MONITOR_REGISTER_ASSETS: ['기기 선택', 'Register Assets', undefined],
  I18N_AST_MONITOR_DIAGNOSTIC_FILE_DOWNLOAD: ['진단 파일 다운로드', 'Diagnostic File Download', undefined],
  I18N_AST_MONITOR_DIRECTION: ['방향', 'Direction', undefined],
  I18N_AST_MONITOR_SPEED: ['속도', 'Speed', undefined],
  I18N_AST_MONITOR_REDUCTION_RATE: ['에너지 절감률', 'Reduction Rate', undefined],
  I18N_AST_MONITOR_ENERGY_SAVING: ['에너지 절감량', 'Energy Saving', undefined],
  I18N_AST_MONITOR_ENERGY_SAVING_TOOLTIP: ['기기 설치 후 총 절감량을 표시합니다. 60Hz 사용 기준으로하며, 사용 환경이나 상황에 따라 편차가 있을 수 있습니다.', 'Total savings since the device is installed. Based on 60Hz usage.', undefined],
  I18N_AST_MONITOR_CO2_REDUCTION: ['CO2 절감량', 'CO2 Reduction', undefined],
  I18N_AST_MONITOR_CONTROL: ['원격 제어', 'Control', undefined],
  I18N_AST_MONITOR_TOTAL_HEALTH_ANALYSIS: ['건강도 분석', 'Total Health Analysis', undefined],
  I18N_AST_MONITOR_AI_ASSET_ANALYSIS: ['AI 기기 분석', 'AI Asset Analysis', undefined],
  I18N_AST_MONITOR_AI_MOTOR_ANALYSIS: ['AI 모터 분석', 'AI Motor Analysis', undefined],
  I18N_AST_MONITOR_TOTAL_STATUS: ['전체 상태', 'Total Status', undefined],
  I18N_AST_MONITOR_TROUBLE_SHOOTING: ['문제 해결', 'Trouble Shooting', undefined],
  I18N_AST_MONITOR_DRIVE_STATUS: ['LV Drive 상태', 'Drive Status', undefined],
  I18N_AST_MONITOR_MOTOR_MONITORING: ['모터 모니터링', 'Motor Monitoring', undefined],
  I18N_AST_MONITOR_MOTOR_MONITORING_MEASURE: ['AI를 이용하여 모터의 건강도를 측정합니다.', 'Use AI to measure the health of the motor.', undefined],
  I18N_AST_MONITOR_BEARING_MEASURE: ['Bearing의 수명을 측정합니다.', 'The bearing of the motor may slightly damaged. The onsite engineer should perform maintenance on the motor.', undefined],
  I18N_AST_MONITOR_COIL_MEASURE: ['코일의 소손 정도를 판단합니다.', 'The coil winding condition of the motor is good.', undefined],
  I18N_AST_MONITOR_POWER_EFFICIENCY_MEASURE: ['모터의 토크 값과 전류의 상태를 측정하여 Power의 건강도를 판단합니다.', 'The power efficiency of the motor is good.', undefined],
  I18N_AST_MONITOR_RECOMMENDED_TIME_OF_USE: ['추천 사용 시간 기준으로 측정 됩니다.', 'Estimates based on the recommended time of use.', undefined],
  I18N_AST_MONITOR_EMPTY_ASSET_TIT: ['모니터링할 자산이 없습니다.', 'There is no asset to monitor.', undefined],
  I18N_AST_MONITOR_EMPTY_ASSET_DESC: ['첫 자산을 등록해 보세요.', 'Register your first asset.', undefined],
  I18N_AST_MONITOR_EMPTY_ASSET_BTN_ADD_DEMO: ['데모 프로젝트 추가하기', 'Add a DEMO Project', undefined],
  I18N_AST_MONITOR_EMPTY_ASSET_BTN_VIEW_HELP: ['도움말 보기', 'Go to Guide Menu', undefined],

  I18N_AST_MONITOR_VIEW_DETAILS: ['상세보기', 'View Details', undefined],
  I18N_AST_MONITOR_ANALYSIS_RESULT: ['분석 결과', 'Analysis Result', undefined],
  I18N_AST_MONITOR_ENERGY_USAGE: ['에너지 사용량', 'Energy Usage', undefined],

  I18N_AST_MONITOR_CAP_USED_RATE: ['커패시터 사용률', 'Capacitor Used Rate'],
  I18N_AST_MONITOR_CAP_NORMAL: ['인버터 메인 Capacitor의 열화 정도가 70% 이하입니다.', 'The degradation of the inverter main capacitor is below 70%.'],
  I18N_AST_MONITOR_CAP_WARN: ['인버터 메인 Capacitor의 열화 정도가 70%이상입니다. 인버터를 확인해 주세요.', 'The inverter main capacitor is more than 70% degraded, please check the inverter.'],

  I18N_AST_MONITOR_FAN_USED_RATE: ['팬 사용률', 'Fan Used Rate'],
  I18N_AST_MONITOR_FAN_NORMAL: ['인버터 팬의 누적 사용시간이 90% 이하 입니다.', 'The cumulative usage time of the inverter fan is 90% or less.'],
  I18N_AST_MONITOR_FAN_WARN: ['인버터 팬의 누적 사용시간이 90% 이상 입니다.', 'The cumulative usage time of the inverter fan is greater than 90%.'],

  I18N_AST_DEVICE_DETAIL: ['기기 상세', 'Asset Details', undefined],
  I18N_AST_DEVICE_THUMB_NAIL_IMAGE: ['미리보기 이미지', 'Thumb Nail Image', undefined],
  I18N_AST_DEVICE_NAME: ['기기명', 'Asset Name', undefined],
  I18N_AST_DEVICE_SERIAL_NUM: ['시리얼 번호', 'Serial Number', undefined],
  I18N_AST_DEVICE_MODEL_NAME: ['모델', 'Model Name', undefined],
  I18N_AST_DEVICE_LON_LAT: ['위경도', 'Latitude & Longitude', undefined],
  I18N_AST_DEVICE_TIMEZONE: ['Timezone', 'Timezone', undefined],
  I18N_AST_DEVICE_DIS_ALARM: ['Disconnected 알람 간격', 'Disconnected Alarm', undefined],

  I18N_AST_TREND_ENERGY_USAGE: ['에너지 사용량', 'Energy Usage', undefined],
  I18N_AST_TREND_CUSTOM: ['기간 선택', 'Custom', undefined],
  I18N_AST_TREND_CUSTOM_TOOLTIP: ['30일 이전의 데이터 선택시 시간단위로만 볼 수 있습니다. 초단위 데이터는 다운로드를 이용해주세요.', 'Data older than 30 days is only available in hourly units. For second-unit data, please use the download button. ', undefined],
  I18N_AST_TREND_HISTORY: ['이력', 'History', undefined],
  I18N_AST_TREND_RECORDS: ['기록', 'Records', undefined],
  I18N_AST_TREND_TIME: ['발생 일시', 'Time', undefined],
  I18N_AST_TREND_TOTAL: ['전체', 'Total', undefined],
  I18N_AST_TREND_DOWNLOAD_UNIT: ['단위', 'Unit', undefined],
  I18N_AST_TREND_DOWNLOAD_TITLE: ['다운로드', 'Data Download', undefined],
  I18N_AST_TREND_DOWNLOAD_UNIT_HOUR: ['시간 (초 단위 데이터의 평균)', 'Hour (Average of Seconds Data)', undefined],
  I18N_AST_TREND_DOWNLOAD_TIME: ['시간', 'Time', undefined],
  I18N_AST_TREND_DOWNLOAD_SECOND: ['초', 'Second', undefined],
  I18N_AST_TREND_DOWNLOAD_REMARK: ['다운되는 파일의 시각은 UTC(국제표준시간) 기준으로 표기됩니다.', 'The time of the file is shown in UTC (International Time Zone).', undefined],
  I18N_AST_TREND_DOWNLOAD_SELECTED: ['선택', 'Selected', undefined],
  I18N_AST_TREND_RECORDS_REGISTRATION_TIME: ['발생 일시', 'Registration Time', undefined],
  I18N_AST_TREND_RECORDS_CAPACITY: ['용량 (kbyte)', 'Capacity (kbyte)', undefined],
  I18N_AST_TREND_RECORDS_PROJECT: ['프로젝트', 'Project', undefined],
  I18N_AST_TREND_RECORDS_ASSET: ['기기', 'Asset', undefined],
  I18N_AST_TREND_RECORDS_REFER: ['참조', 'Refer', undefined],

  I18N_AST_ALARM_DEVICE_ALARM: ['기기 알람', 'Device Alarm', undefined],
  I18N_AST_ALARM_SYSTEM_ALARM: ['시스템 알람', 'System Alarm', undefined],
  I18N_AST_ALARM_TYPE_DEVICE: ['기기', 'Device', undefined],
  I18N_AST_ALARM_TYPE_SYSTEM: ['시스템', 'System', undefined],
  I18N_AST_ALARM_STATUS: ['상태', 'Status', undefined],
  I18N_AST_ALARM_DESC: ['설명', 'Description', undefined],
  I18N_AST_ALARM_PROJECT: ['프로젝트', 'Project', undefined],
  I18N_AST_ALARM_DEVICE: ['기기', 'Asset', undefined],
  I18N_AST_ALARM_OCCURRED_TIME: ['발생 시각', 'Occurred Time', undefined],
  I18N_AST_ALARM_RESOLVED_TIME: ['해제 시각', 'Resolved Time', undefined],
  I18N_AST_ALARM_CONFIRMED_BY: ['확인자', 'Confirmed by', undefined],
  I18N_AST_ALARM_EVENT: ['이벤트', 'Event', undefined],
  I18N_AST_ALARM_DETAILS: ['상세', 'Details', undefined],
  I18N_AST_ALARM_DETAILS_TIT: ['알람 상세', 'Alarm Details', undefined],
  I18N_AST_ALARM_DETAILS_TYPE: ['구분', 'Type', undefined],

  I18N_AST_NEW_ALERTS: ['새 알람', 'New Alerts', undefined],
  I18N_AST_SEARCHING: ['검색', 'Searching', undefined],
  I18N_AST_SEARCHING_FOR_PROJECT: ['프로젝트 검색', 'Searching for Project', undefined],
  I18N_AST_SEARCHING_FOR_ASSET: ['기기 검색', 'Searching for Asset', undefined],
  I18N_AST_SEARCHING_FOR_ADDRESS: ['주소 검색', 'Searching for Address', undefined],

  I18N_AST_SETTING_DEMO_PJT: ['Demo 프로젝트', 'Demo Project', undefined],
  I18N_AST_SETTING_ADD_DEMO_PJT: ['DEMO 프로젝트 & 기기 추가', 'Add Demo Project & Assets', undefined],
  I18N_AST_SETTING_DELETE_DEMO_PJT: ['DEMO 프로젝트 & 기기 삭제', 'Delete Demo Project & Assets', undefined],
  I18N_AST_SETTING_MSG_ADD_DEMO_PJT: ['DEMO 프로젝트를 추가하시겠습니까?', 'Do you want to add a DEMO project?', undefined],
  I18N_AST_SETTING_MSG_DELETE_DEMO_PJT: ['DEMO 프로젝트를 삭제하시겠습니까?', 'Are you sure you want to delete the DEMO project? You can add it back on the Asset Settings page.', undefined],
  I18N_AST_SETTING_ASSET: ['기기 관리', 'Asset', undefined],
  I18N_AST_SETTING_USER: ['공유 관리', 'Share', undefined],
  I18N_AST_SETTING_ENABLE: ['기기 사용', 'Enable', undefined],
  I18N_AST_SETTING_STATUS: ['기기 상태', 'Status', undefined],
  I18N_AST_SETTING_TYPE: ['구분', 'Type', undefined],
  I18N_AST_SETTING_OWNER: ['소유자', 'Owner', undefined],
  I18N_AST_SETTING_OWNER_USER: ['소유', 'Owner', undefined],
  I18N_AST_SETTING_SHARED_USER: ['공유', 'Shared', undefined],
  I18N_AST_SETTING_NAME: ['기기명', 'Name', undefined],
  I18N_AST_SETTING_MODEL: ['모델', 'Model', undefined],
  I18N_AST_SETTING_LAST_UPDATE: ['최근 접속', 'Last Update', undefined],
  I18N_AST_SETTING_URL: ['URL', 'URL', undefined],
  I18N_AST_SETTING_SHARE: ['공유', 'Share', undefined],
  I18N_AST_SETTING_DETAILS: ['상세', 'Details', undefined],
  I18N_AST_SETTING_EMAIL_ALARM: ['이메일 알람', 'E-mail Alarm', undefined],
  I18N_AST_SETTING_MOBILE_ALARM: ['모바일 알람', 'Mobile Alarm', undefined],
  I18N_AST_SETTING_SIGN_OUT: ['로그아웃', 'Sign Out', undefined],
  I18N_AST_SETTING_UNAUTH_OWNER: ['본인 인증', 'Authentication', undefined],
  I18N_AST_SETTING_UNAUTH: ['인증 요청', 'Authentication', undefined],

  I18N_AST_PROFILE_USER_ACCOUNT: ['사용자 계정', 'User Account', undefined],
  I18N_AST_PROFILE_USER_ID: ['사용자 ID', 'User ID', undefined],
  I18N_AST_PROFILE_TYPE: ['권한', 'Type', undefined],
  I18N_AST_PROFILE_USER_INFORMATION: ['계정 정보', 'User Information', undefined],
  I18N_AST_PROFILE_NICKNAME: ['별명', 'Nickname', undefined],
  I18N_AST_PROFILE_MOBILE: ['모바일 번호', 'Mobile', undefined],
  I18N_AST_PROFILE_SETTING: ['계정 설정', 'Preference Setting', undefined],
  I18N_AST_PROFILE_SITE: ['사이트', 'Site', undefined],
  I18N_AST_PROFILE_ACCOUNT_SETTING: ['계정 관리', 'Account Setting', undefined],
  I18N_AST_PROFILE_NOTI_SETTING: ['알람 설정', 'Notification Setting', undefined],

  I18N_AST_MODIFY: ['수정', 'Modify', undefined],
  I18N_AST_CLEAR: ['초기화', 'Clear', undefined],
  I18N_AST_APPLY: ['적용', 'Apply', undefined],
  I18N_AST_DELETE: ['삭제', 'Delete', undefined],
  I18N_AST_CANCEL: ['취소', 'Cancel', undefined],
  I18N_AST_SAVE: ['저장', 'Save', undefined],
  I18N_AST_SHARE: ['공유', 'Share', undefined],
  I18N_AST_CLOSE: ['닫기', 'Close', undefined],
  I18N_AST_SEND: ['전송', 'Send', undefined],
  I18N_AST_OK: ['확인', 'OK', undefined],
  I18N_AST_TODAY: ['오늘', 'Today', undefined],
  I18N_AST_DOWNLOAD: ['다운로드', 'Download', undefined],
  I18N_AST_YESTERDAY: ['어제', 'Yesterday', undefined],

  I18N_AST_THIS_WEEK: ['이번주', 'This week', undefined],
  I18N_AST_LAST_WEEK: ['지난주', 'Last week', undefined],
  I18N_AST_THIS_MONTH: ['이번달', 'This month', undefined],
  I18N_AST_LAST_MONTH: ['지난달', 'Last month', undefined],
  I18N_AST_THIS_YEAR: ['올해', 'This year', undefined],
  I18N_AST_LAST_YEAR: ['작년', 'Last year', undefined],


  I18N_AST_MIN: ['분', 'min', undefined],
  I18N_AST_HOUR: ['시간', 'hour', undefined],
  I18N_AST_DAY: ['일', 'day', undefined],
  I18N_AST_DAYS: ['일', 'days', undefined],
  I18N_AST_WEEKS: ['주', 'weeks', undefined],

  I18N_AST_TYPE: ['구분', 'Type', undefined],
  I18N_AST_GUIDE: ['가이드', 'Guide', undefined],
  I18N_AST_DETAILS: ['가이드', 'Details', undefined],
  I18N_AST_DESC: ['설명', 'Description', undefined],
  I18N_AST_REGISTRATION_TIME: ['등록 일시', 'Registration Time', undefined],
  I18N_AST_LAST_ACCESS: ['최근 접속', 'Last Access', undefined],
  I18N_AST_FILTER_DELETE: ['필터 초기화', 'Filter Delete', undefined],

  I18N_AST_MANUAL_WRITE_CONFIRM: ['저장 하시겠습니까?', 'Are you sure you want to save this post?', undefined],
  I18N_AST_MANUAL_CONFIRM: ['삭제 하시겠습니까?', 'Are you sure you want to delete it?', undefined],
  I18N_AST_CHK_FILE_TYPE: ['이미지 파일만 등록 가능합니다.', 'Only image files can be registered', undefined],
  I18N_AST_CHK_DUP_PJT_NAME: ['중복된 이름이 있습니다. 변경 부탁드립니다.', 'There are duplicate names. Please change it.', undefined],
  I18N_AST_CONFIRM_DELETE_PROJECT_TITLE: ['프로젝트 삭제', 'Delete Project', undefined],
  I18N_AST_CONFIRM_DELETE_PROJECT_MSG: ['해당 프로젝트가 삭제됩니다.', 'The Project will be deleted.', undefined],
  I18N_AST_CONFIRM_DELETE_ASSET_TITLE: ['Asset 삭제', 'Delete Asset', undefined],
  I18N_AST_CONFIRM_DELETE_ASSET_MSG: ['이 프로젝트에서 기기가 보이지 않게 변경됩니다.', 'Your device will become invisible in this project.', undefined],
  I18N_AST_CONFIRM_DELETE_CONTINUE: ['계속 진행하시겠습니까?', 'Do you want to continue?', undefined],
  I18N_AST_CONFIRM_DELETE_REMARK: ['(기기 삭제는 설정 페이지에서 진행해 주세요.)', '(To delete the device, go to the Settings page).', undefined],

  I18N_AST_RMC_CMDSRC_0: ["Smart Operator", "Smart Operator", undefined],
  I18N_AST_RMC_CMDSRC_1: ["통신 옵션", "Option Comm", undefined],
  I18N_AST_RMC_CMDSRC_2: ["App/PLC", "App/PLC", undefined],
  I18N_AST_RMC_CMDSRC_3: ["내장형 RS485 통신", "Built-in 485 Comm", undefined],
  I18N_AST_RMC_CMDSRC_4: ["단자대", "Terminal block", undefined],
  I18N_AST_RMC_CMDSRC_5: ["USB", "USB", undefined],

  I18N_AST_RMC_FREQSRC_0: ["Smart Operator speed", "Smart Operator speed", undefined],
  I18N_AST_RMC_FREQSRC_1: ["Smart Operator torque", "Smart Operator torque", undefined],
  I18N_AST_RMC_FREQSRC_2_4: ["업/다운 운전속도", "Up/Down operation speed", undefined],
  I18N_AST_RMC_FREQSRC_5: ["AI1", "AI1", undefined],
  I18N_AST_RMC_FREQSRC_6: ["AI2", "AI2", undefined],
  I18N_AST_RMC_FREQSRC_7: ["AI3", "AI3", undefined],
  I18N_AST_RMC_FREQSRC_9: ["펄스", "Pulse", undefined],
  I18N_AST_RMC_FREQSRC_10: ["내장형 RS485 통신", "Built-in 485 Comm", undefined],
  I18N_AST_RMC_FREQSRC_11: ["통신 옵션", "Option Comm", undefined],
  I18N_AST_RMC_FREQSRC_12: ["App/PLC", "App/PLC", undefined],
  I18N_AST_RMC_FREQSRC_13: ["조그", "Jog", undefined],
  I18N_AST_RMC_FREQSRC_14: ["PID", "PID", undefined],
  I18N_AST_RMC_FREQSRC_15: ["USB", "USB", undefined],
  //I18N_AST_RMC_FREQSRC_25_31: ["다단속 주파수", "다단속 주파수",undefined],
  I18N_AST_RMC_FREQSRC_25: ["다단속 주파수", "Multi-step speed Frequency", undefined],
  I18N_AST_RMC_FREQSRC_55: ["User Sequence", "User Sequence", undefined],
  I18N_AST_RMC_FREQSRC_56: ["확장 옵션 아날로그 1", "Ext Option Analog 1", undefined],
  I18N_AST_RMC_FREQSRC_57: ["확장 옵션 아날로그 2", "Ext Option Analog 2", undefined],
  I18N_AST_RMC_FREQSRC_58: ["확장 옵션 아날로그 3", "Ext Option Analog 3", undefined],
  I18N_AST_RMC_FREQSRC_59: ["Position", "Position", undefined],

  I18N_AST_RMC_ALERT_PARAM_MSG: [`운전제어를 위한 파라미터 설정이 올바르지 않습니다.
드라이브 파라미터 페이지에서 'Cmd Src' 와
'Freq Ref Src' 를 'Option Comm'로 변경해주세요.`, `Parameter settings for operation control are incorrect.
On the drive parameters page, type 'Cmd Src' and
Please change 'Freq Ref Src' to 'Option Comm'.`, undefined],

  I18N_AST_RMC_TOAST_CTL_OK: ['제어를 성공적으로 실행했습니다.', 'The control ran successfully.', undefined],
  I18N_AST_RMC_CTL_FAIL: ['제어 실패', 'Control Failure', undefined],
  I18N_AST_RMC_CTL_FAIL_MSG: ['제어 메시지를 전달하였으나 제어가 정상적으로 실행되지 않았습니다. 기기 상태를 확인해 주세요.',
    'The control request was not successfully applied. Please check your device status.', undefined],
  I18N_AST_RMC_AUTH_TIMEOUT_TIT: ['인증 기간 만료', 'Authentication Period Expiration', undefined],
  I18N_AST_RMC_AUTH_TIMEOUT_MSG: ['인증이 만료 되었습니다. 다시 제어를 활성화해 주세요.', 'Your authentication has expired. Please enable controls.', undefined],
  I18N_AST_RMC_UNDER_CTL_TIT: ['제어 중인 기기', 'The Device Being Controlled', undefined],
  I18N_AST_RMC_UNDER_CTL_MSG: [`제어 중인 기기 입니다.
제어를 위해서는 제어 중인 사용자가 ‘원격 제어’를 ‘Off’로 변경해야 합니다.`, `This device is being controlled.
To control this device, the user controling  must change 'Remote Control' to 'Off'.`, undefined],

  I18N_AST_RMC_AUTH_CTLSET_TIT: ['제어 권한 설정 오류', 'Control Permission Setting Error', undefined],
  I18N_AST_RMC_AUTH_CTLSET_MSG: [`해당 계정은 제어 권한이 없습니다. 기기 소유자({{owner}})에게 제어 권한을 요청해 주세요.`,
    `Your account doesn't have control permissions. Request control from the device owner({{owner}}).`, undefined],
  I18N_AST_RMC_FAIL_GET_CTLINFO_MSG: ['제어 요청에 응답이 없습니다.', 'No response to control requests.', undefined],
  I18N_AST_RMC_CTL_CONFIRM_MSG: ['정말 “{{from}}” 에서 “{{to}}”로 변경하시겠습니까?', 'Are you sure you want to change from “{{from}}” to “{{to}}”?', undefined],

  I18N_AST_RMC_AUTH_EXPIRED_TIT: ["인증 기간 만료", "Authentication Period Expiration", undefined],
  I18N_AST_RMC_AUTH_EXPIRED_MSG: [`인증이 만료 되었습니다.
다시 제어를 활성화해 주세요`, `Your authentication has expired.
Please enable controls.`, undefined],

  I18N_AST_DIR_STOP: ['정지', 'Stopped', undefined],
  I18N_AST_DIR_DC_OPER: ['DC운전', 'DC Operation', undefined],

  I18N_AST_SPEED_ACCELERATING: ['가속', 'Accelerating', undefined],
  I18N_AST_SPEED_HW_OCS: ['HW과전류 억제', 'H/W OCS', undefined],
  I18N_AST_SPEED_SW_OCS: ['SW과전류 억제', 'S/W OCS', undefined],
  I18N_AST_SPEED_DECELERATING: ['감속', 'Decelerating', undefined],
  I18N_AST_SPEED_SPEED_SEARCHING: ['속도 검색중', 'Speed Searching', undefined],
  I18N_AST_SPEED_DECELERATING_TO_STOP: ['감속정지', 'Decelerating to Stop', undefined],
  I18N_AST_SPEED_OPERATING_AT_CONSTANT_RATE: ['정속', 'Operating at Constant Rate', undefined],
  I18N_AST_SPEED_DWELL_OPERATING: ['드웰', 'Dwell', undefined],

  I18N_AST_SHARE_TITLE: ['사용자에게 공유', 'Share to User', undefined],
  I18N_AST_SHARE_ADD_MSG: ['추가할 계정 입력', 'Enter the Account to Add', undefined],
  I18N_AST_SHARE_USER: ['사용자 공유', 'Share User', undefined],
  I18N_AST_SHARE_EMAIL: ['Email ID', 'Email ID', undefined],
  I18N_AST_SHARE_MONITOR: ['모니터링', 'Monitor', undefined],
  I18N_AST_SHARE_CONTROL: ['제어', 'Control', undefined],
  I18N_AST_SHARE_LASTLOGIN: ['최근 로그인', 'Last Login', undefined],

  I18N_AST_SETTING_USER_ID: ['사용자 ID', 'User ID', undefined],
  I18N_AST_SETTING_USER_NICKNAME: ['프로필 이름', 'Nickname', undefined],
  I18N_AST_SETTING_USER_SHARED: ['공유된 자산 수', 'Shared Assets(EA)', undefined],
  I18N_AST_SETTING_USER_LASTLOGIN: ['최근 로그인', 'Last Login', undefined],
  I18N_AST_SETTING_USER_DETAILS: ['상세', 'Details', undefined],

  I18N_AST_SETTING_USER_DETAILS_POP: ['사용자 상세 정보', 'User Details', undefined],
  I18N_AST_SETTING_USER_SHARED_MSG: ['공유할 계정 입력', 'Enter the Account to Share', undefined],
  I18N_AST_SETTING_USER_MY_ASSETS: ['내 자산', 'My Assets', undefined],
  I18N_AST_SETTING_USER_ASSET_NAME: ['기기명', 'Asset Name', undefined],
  I18N_AST_SETTING_USER_MONITOR: ['모니터링', 'Monitor', undefined],
  I18N_AST_SETTING_USER_CONTROL: ['제어', 'Control', undefined],
  I18N_AST_SETTING_USER_ASSET_TIME: ['등록 시각', 'Registration Time', undefined],

  I18N_AST_INVITATION_SEND_MAIL: ['초대 메일 보내기', 'Send Invitation Mail', undefined],
  I18N_AST_SHARE_SEND_MAIL: ['공유 메일 보내기', 'Send Share Mail', undefined],
  I18N_AST_INVITATION_EMAIL: ['Email ID', 'Email ID', undefined],
  I18N_AST_INVITATION_LANGUAGE: ['언어', 'Language', undefined],
  I18N_AST_INVITATION_MAIL_MSG: ['메시지', 'Mail Message', undefined],
  I18N_AST_INVITATION_USER_DETAILS: ['상세', 'Details', undefined],

  I18N_HELP_SEARCH_PLACEHOLDER: ["키워드 입력", "Enter Keywords", undefined],
  I18N_HELP_ADD_PAGE: ["페이지 추가", "Add Page", undefined],
  I18N_HELP_ADD_CATEGORY: ["새 카테고리", "New Category", undefined],

  I18N_AST_PAGE_SUFFIX: [" - AssetInsight - 솔루션스퀘어", " - AssetInsight - Solution Square", " - AssetInsight - Solution Square(US)"],
  I18N_AST_PAGE_MONITOR: ["모니터링", "Monitor", undefined],
  I18N_AST_PAGE_PJTMON: ["프로젝트모니터링", "ProjectMonitor", undefined],
  I18N_AST_PAGE_DEVMON: ["디바이스모니터링", "DeviceMonitor", undefined],
  I18N_AST_PAGE_TREND: ["트렌드", "Trend", undefined],
  I18N_AST_PAGE_PARAM: ["파라미터", "Parameter", undefined],
  I18N_AST_PAGE_ALARM: ["알람", "Alarm", undefined],
  I18N_AST_PAGE_SETTING: ["설정", "Setting", undefined],
  I18N_AST_PAGE_HELP: ["도움말", "Help", undefined],
  I18N_AST_PAGE_PROFILE: ["프로필", "Profile", undefined],

  I18N_AST_TERMS: ["이용 약관", "Terms and Conditions", undefined],
  I18N_AST_PRIVACY_POLICY: ["개인정보 처리방침", "Privacy Policy", undefined],
  I18N_COOKIE_POPUP_POLICY_DETAIL: ['쿠키 정책', 'Cookie Policy', undefined],
  I18N_COOKIE_POPUP_CONFIGURE: ['쿠키 설정', 'Configure', undefined],
  I18N_COOKIE_POPUP_CONFIGURE_INTRO: ['Solution Square는 쿠키를 사용합니다.', 'Our website uses cookies.', undefined],
  I18N_COOKIE_POPUP_CONFIGURE_PART: ['사용 중인 기능 쿠키 목록 입니다.', 'List of functional cookies in use.', undefined],
  I18N_COOKIE_POPUP_ESSENTIAL: ['필수 쿠키', 'Essential Cookies', undefined],
  I18N_COOKIE_POPUP_ANALYTICAL: ['분석 쿠키', 'Analytical / Performance Cookies', undefined],
  I18N_COOKIE_POPUP_FUNCTIONALITY: ['기능 쿠키', 'Functionality and Profile Cookies', undefined],
  I18N_COOKIE_POPUP_ADVERTISING: ['마케팅 활용 쿠키', 'Advertising / Targeting Cookies', undefined],
  I18N_COOKIE_POPUP_REFUSE_ALL: ['전체 거절', 'Refuse All', undefined],
  I18N_COOKIE_POPUP_ACCEPT: ['동의', 'Accept', undefined],
  I18N_COOKIE_POPUP_AGREE: ['동의', 'Agree', undefined],
  I18N_COOKIE_POPUP_ACCEPT_ALL: ['전체 수락', 'Accept All', undefined],
  I18N_COOKIE_POPUP_CONFIRM_CHOICE: ['선택 저장', 'Confirm My Choice', undefined],
  I18N_COOKIE_POPUP_TYPE: ['구분', 'Cookie Type', undefined],
  I18N_COOKIE_POPUP_COOKIE_NAME: ['쿠키명', 'Cookie Name', undefined],
  I18N_COOKIE_POPUP_COOKIE_DOMAIN: ['도메인', 'Domain', undefined],
  I18N_COOKIE_POPUP_COOKIE_COMPANY: ['회사명', 'Company', undefined],
  I18N_COOKIE_POPUP_OK: ['확인', 'OK', undefined],
  I18N_COOKIE_POPUP_CONFIRM: ['확인', 'Confirm', undefined],

  I18N_MODIFY_POPUP_TITLE: ["내 정보", "My information", undefined],
  I18N_MODIFY_POPUP_NOTIFICATION: ["정보를 입력한 후 저장해 주세요.", "Register your info. and save it.", undefined],
  I18N_MODIFY_POPUP_MANDATORY_INPUT: ["필수 입력값", "Required fields", undefined],
  I18N_MODIFY_POPUP_EMAIL: ["메일 주소", "E-mail", undefined],
  I18N_MODIFY_POPUP_PASSWORD: ["비밀번호", "Password", undefined],
  I18N_MODIFY_POPUP_PASSWORD_CHECK: ["비밀번호 확인", "Confirm Password", undefined],
  I18N_MODIFY_POPUP_LEAVE_POPUP_ALERT: ["회원가입 페이지를 나가시겠습니까? 이미 입력된 정보는 사라집니다", "Are you sure you want to leave this page?", undefined],
  I18N_MODIFY_POPUP_INVALID_PASSWORD_PLACEHOLDER: ["10~16자 텍스트, 영문 / 숫자 포함", "10~16 characters, a mix of letters, numbers.", undefined],
  I18N_MODIFY_POPUP_INVALID_PASSWORD_ALERT: ["10~16자 텍스트, 영문 / 숫자를 포함하여 입력해 주세요.", "10-16 characters, a mix of letters and numbers", undefined],
  I18N_MODIFY_POPUP_PASSWORD_CHECK_ALERT: ["비밀번호가 일치하지 않습니다.", "The password does not matched.", undefined],
  I18N_MODIFY_POPUP_NICKNAME: ["프로필 이름", "Nickname", undefined],
  I18N_MODIFY_POPUP_BUSINESS: ["추가 정보", "Business", undefined],
  I18N_MODIFY_POPUP_NICKNAME_PLACEHOLDER: ["10자 이하 텍스트", "Max. 10 characters", undefined],
  I18N_MODIFY_POPUP_NICKNAME_INVALID_ALERT: ["10자 이하 텍스트를 입력해 주세요.", "Enter max. 10 characters.", undefined],
  I18N_MODIFY_POPUP_NAME_PLACEHOLDER: ["20자 이하 텍스트", "Max. 20 characters", undefined],
  I18N_MODIFY_POPUP_NAME_INVALID_ALERT: ["20자 이하 텍스트를 입력해 주세요.", "Enter max. 20 characters.", undefined],
  I18N_MODIFY_POPUP_NICKNAME_DUPLICATE_ALERT: ["이미 존재하는 별명입니다.", "This nickname already exists", undefined],
  I18N_MODIFY_POPUP_NAME: ["성명", "Name", undefined],
  I18N_MODIFY_POPUP_FIRST_NAME: ["이름", "First name", undefined],
  I18N_MODIFY_POPUP_LAST_NAME: ["성", "Last name", undefined],
  I18N_MODIFY_POPUP_COUNTRY: ["국가", "Country", undefined],
  I18N_MODIFY_POPUP_PHONE_NUMBER: ["전화 번호", "Phone Number", undefined],
  I18N_MODIFY_POPUP_PHONE_NUMBER_PLACEHOLDER: ["숫자만", "Only number", undefined],
  I18N_MODIFY_POPUP_COUNTRY_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE: ["업종", "Job", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_MACHINE_MAKER: ["기계업체", "Machine builder", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_SMALL_WHOLESALE: ["소/도매", "Wholesaler", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_SET_MAKER: ["세트메이커", "System Integrator", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_ELECTRIC_COMPANY: ["전기업체", "Electrical", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_DEALERSHIP: ["특약점", "Distributor", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_PANEL_MAKER: ["판넬업체", "Panel maker", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_STUDENT: ["학생", "Educational Institution", undefined],
  I18N_MODIFY_POPUP_MEMBER_TYPE_ETC: ["기타", "Etc.", undefined],
  I18N_MODIFY_POPUP_SCHOOL: ["학교", "Institution", undefined],
  I18N_MODIFY_POPUP_SCHOOL_PLACEHOLDER: ["ex) OO대학교", "ex) OOO University", undefined],
  I18N_MODIFY_POPUP_MAJOR_GRADE: ["학과/학년", "Department", undefined],
  I18N_MODIFY_POPUP_MAJOR_GRADE_PLACEHOLDER: ["ex) 전기전자공학/3학년", "ex) Major / 3rd", undefined],
  I18N_MODIFY_POPUP_COMPANY: ["회사", "Company", undefined],
  I18N_MODIFY_POPUP_COMPANY_PLACEHOLDER: ["ex) LS ELECTRIC", "ex) LS ELECTRIC", undefined],
  I18N_MODIFY_POPUP_DEPARTMENT_TITLE: ["부서/직위", "Department / Job level", undefined],
  I18N_MODIFY_POPUP_DEPARTMENT_TITLE_PLACEHOLDER: ["ex) 기술팀/과장", "ex)Marketing / Manager", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD: ["관심 산업", "Industry", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_SELECT: ["선택", "Select", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_RECHARGEABLE_BATTERY: ["2차전지", "Battery", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_PROCESSING_MACHINE: ["가공기", "Machining Center", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_CONSTRUCTION: ["건설", "Construction", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_RUBBER: ["고무", "Rubber / Plastic", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_AIRPORT_AIR: ["공항/항공", "Airport", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_METAL: ["금속", "Metals", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_DISPLAY: ["디스플레이", "Display", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_ROBOT: ["로봇", "Robotics", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_MOBILE: ["모바일", "Mobile", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_CARPENTRY: ["목공", "Wood", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_SEMICONDUCTOR: ["반도체", "Semiconductor", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_DEVELOPMENT: ["발전", "Power", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_FIBER: ["섬유", "Fiber / Textiles", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_WATER_TREATMENT: ["수처리", "Water", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_SMART_FACTORY: ["스마트공장", "Smart Factory", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_FOOD: ["식품", "Food / Beverage", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_ELEVATOR: ["엘리베이터", "Elevator", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_GLASS_CEMENT: ["유리/시멘트", "Glass / Cement", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_MEDICAL_TREATMENT: ["의료", "Healthcare / Bio", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_CAR: ["자동차", "Automotive", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_ELECTRIC: ["전기", "Electric Utility", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_RESTRAINT: ["제지", "Pulp / Paper", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_STEEL: ["철강", "Steel", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_CONVEYOR: ["컨베이어", "Conveyor", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_PACKING_MACHINE: ["포장기", "Packaging", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_PORT_SHIPBUILDING: ["항만/조선", "Marine", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_CHEMISTRY: ["화학", "Chemical", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_ENVIRONMENT: ["환경", "Environment", undefined],
  I18N_MODIFY_POPUP_INTEREST_FIELD_TYPE_ETC: ["기타", "Etc.", undefined],
  I18N_MODIFY_POPUP_ACCEPT_TERMS_CONDITIONS: ["이용 약관", "Terms", undefined],
  I18N_MODIFY_POPUP_ACCEPT_ALL: ["전체 동의", "Accept All", undefined],
  I18N_MODIFY_POPUP_ACCEPT_TERMS: ["(필수) 이용 약관 동의", "(Required) Terms and Conditions", undefined],
  I18N_MODIFY_POPUP_ACCEPT_PRIVATE_ESSENTIAL: ["(필수) 개인정보 수집 및 이용 동의", "(Required) Personal Information Collection and Use Agreement", undefined],
  I18N_MODIFY_POPUP_ACCEPT_PRIVATE_ESSENTIAL_DETAIL: ["이용 약관", "Terms", undefined],
  I18N_MODIFY_POPUP_ACCEPT_PRIVATE_OPTIONAL: ["(선택) 마케팅 활용 동의", "(Optional) Privacy Policy", undefined],
  I18N_MODIFY_POPUP_ACCEPT_PRIVATE_OPTIONAL_DETAIL: ["이용 약관", "Terms", undefined],
  I18N_MODIFY_POPUP_ACCEPT_MAIL: ["(선택) 뉴스레터 수신 동의", "(Optional) Consent to receive newsletter", undefined],
  I18N_MODIFY_POPUP_ACCEPT_MAIL_NOTIFICATION: ["수신 동의 시 LS ELECTRIC 뉴스레터를 메일로 보내드립니다.", "Subscribe to LS ELECTRIC Newsletter", undefined],
  I18N_MODIFY_POPUP_REQUIRED_ALERT: ["필수 항목 입니다.", "This field is required.", undefined],
  I18N_MODIFY_POPUP_USERINFO_LEAVE: ["탈퇴하기", "Delete", undefined],
  I18N_MODIFY_POPUP_USERINFO_SAVE: ["저장하기", "Save", undefined],
  I18N_MODIFY_POPUP_SAVE_ALERT: ["정보가 변경되었습니다.", "Information has been changed.", undefined],
  I18N_MODIFY_POPUP_LEAVE_STRING: ['탈퇴하시겠습니까?', 'Are you sure you want to delete your account?', undefined],

  I18N_AST_HELP_GUIDE_LINK: ['Asset Insight 바로가기', 'Go to Asset Insight', undefined],

  I18N_ADD_DASHBOARD: ['대시보드 추가', 'Add Dashboard', undefined],
  I18N_DASHBOARD_NODATA: ['추가된 카드가 없습니다.', 'No Cards Added', undefined],
  I18N_DASHBOARD_SHARE_TITLE: ['공유', 'Share', undefined],
  I18N_DASHBOARD_SHARE_ADD_MSG: ['공유할 계정 입력', 'Enter the Accounts to Share', undefined],
  I18N_NEW_DASHBOARD_NAME: ['임시 대시보드', 'New Dashboard', undefined],
  I18N_ADD_DASHBOARD_CARD: ['위젯 추가', 'Add Cards', undefined],

  I18N_ADD_WIDGETS_TITLE: ['제목', 'Title', undefined],
  I18N_ADD_WIDGETS_PERIOD: ['기간', 'Period', undefined],
  I18N_ADD_WIDGETS_LEGEND: ['범례', 'Legend', undefined],
  I18N_SELECT_ASSET: ['기기 선택', 'Select an Asset', undefined],
  I18N_SELECT_COLUMN: ['데이터 선택', 'Select a Data Column', undefined],
  I18N_LABEL_PLACEHOLDER: ['항목(10자 이하 텍스트)', 'Label(Max. 10 Characters)', undefined],


  I18N_AST_POP_CONTROL_HISTORY: ['이력 보기', 'EN_이력 보기', undefined],
  I18N_AST_POP_CONTROL_HISTORY_TITLE: ['Control History', 'Control History', undefined],
  I18N_AST_POP_CONTROL_HISTORY_TIME: ['Time', 'Time', undefined],
  I18N_AST_POP_CONTROL_HISTORY_COMMAND: ['Command', 'Command', undefined],
  I18N_AST_POP_CONTROL_HISTORY_USER: ['User', 'User', undefined],
  I18N_AST_POP_CONTROL_HISTORY_RESULT: ['Result', 'Result', undefined],

};

export type RES_ASSET = keyof typeof resi18n_asset;

const [ko, en, enus] = resi18nutil.getres(resi18n_asset);

export const resi18n_asset_ko = ko;
export const resi18n_asset_en = en;
export const resi18n_asset_enus = enus;



